'use strict';

$(
    function () {
        try {
            var topH = $('._ > .top').height();
            app.fixTop = topH || 0;
        } catch (e) {}
        app.assignNumberControl();
        app.setSelectedLink();
        app.addRegisterCheckBoxes();
        app.attachSvgEvents();
        app.initACBL();
        app.setBackUrls();
        app.addCoverSpan();
        app.saveProductListInfo();
        app.topMenu.init();
        if (!app.isMobile()) {
            $(window).scroll(
                function () {
                    app.floatingHeader();
                }
            );
            app.floatingHeader();
        }
        app.assignProductOver();
        app.initializeScrollButton();
        app.hamburger.init({
            look: 1
        });
        app.color.init();
        app.extraMenu && app.extraMenu.init({
            side: 'right',
            selector: '.extramenu-hamb',
            saveState: false
        });
        app.template.set();
    }
);

var app = {
    _VERSION: 2,
    _vars: {
        v: '1.3',
        tmp: 31,
        putAttributesToProdItemsDirectly: true,
        _guid: 'f90a2a42-f52d-4adb-a1da-aa7936f62b3a',
        _customFonts: {
            Linearicons: true
        }
    },
    responsiveMode: true,
    indexLinkPos: 0,
    messages: {},
    productAvailableQuantityUrl: null,
    optionsWereInitialized: false,
    waitBlockZIndex: 9999,
    template: {
        set: function () {
            this.collapse();
            this.viewMore();
            this.footerCorrections();
            this.userPage();
        },
        initSlider: function (params) {
            var {
                sliderHeight,
                sliderAutoplayTimeout,
                carouselAutoplayTimeout,
                leftMenu
            } = params || {};

            var fullWidth = leftMenu !== 'Y';

            app.events.addListener('udu_AFTER-MAKE-CAROUSEL-DONE', function (info) {
                heightCheck(info);
            });

            mainBanner();
            categoryList();
            servicesList();
            carousel();

            function mainBanner() {
                app.owlSlider.makeBanner({
                    speed: sliderAutoplayTimeout,
                    setHeight: sliderHeight
                });
            }

            function servicesList() {
                var owlParamServices = {
                    autoplayTimeout: sliderAutoplayTimeout,
                    autoHeight: false,
                    autoplay: true,
                    dots: false,
                    loop: false,
                    margin: 0,
                    nav: false,
                    navText: [' ', ' '],
                    responsive: {
                        0: {
                            items: 1
                        },
                        544: {
                            items: 2
                        },
                        761: {
                            items: 3
                        },
                        1200: {
                            items: fullWidth ? 5 : 4
                        }
                    },
                    rewind: true
                };

                $('.services-list:not(.no-data-list)').addClass('owl-carousel').owlCarousel(owlParamServices).addClass('_done');
                app.owlSlider.doAfterSliderDone();
            }

            function categoryList() {
                var $categoryList = $('.category-list');
                var categoryLength = $categoryList.find('.category-item').length;

                if (fullWidth && categoryLength > 4 || !fullWidth && categoryLength > 3) {
                    var owlParamCategory = {
                        autoplayTimeout: sliderAutoplayTimeout,
                        loop: false,
                        margin: 0,
                        nav: true,
                        rewind: true,
                        responsive: {
                            0: {
                                items: 1
                            },
                            544: {
                                items: 2
                            },
                            761: {
                                items: 3
                            },
                            1200: {
                                items: fullWidth ? 4 : 3
                            },
                            1370: {
                                items: fullWidth ? 5 : 4
                            }
                        }
                    };

                    $categoryList.on('changed.owl.carousel initialized.owl.carousel', function (event) {
                        $(event.target)
                            .find('.owl-item').removeClass('first')
                            .eq(event.item.index).addClass('first');
                    });

                    if (fullWidth && categoryLength > 8 || !fullWidth && categoryLength > 6) {
                        var x = $categoryList.children();
                        for (var i = 0; i < x.length + 1; i += 2) {
                            x.slice(i, i + 2).wrapAll('<div class="' + i + '"></div>');
                        }
                    } else {
                        $categoryList.addClass('in-one-row');
                    }

                    app.owlSlider.makeCategoryCarousel(owlParamCategory);
                }
            }

            function carousel() {
                app.owlSlider.makeCarousel({
                    autoHeight: true,
                    autoplay: true,
                    lazyLoad: true,
                    responsive: {
                        0: {
                            items: 1
                        },
                        544: {
                            items: 2
                        },
                        761: {
                            items: 3
                        },
                        992: {
                            items: 5
                        },
                        1200: {
                            items: fullWidth ? 7 : 6
                        }
                    },
                    slideBy: 1,
                    smartSpeed: 1500,
                    speed: carouselAutoplayTimeout
                });
            }

            function heightCheck(info) {
                info || (info = {});

                var data = info.data || '';
                if (!data) {
                    return;
                }

                var maxH = 0;
                var $slider = data.$slider;
                var $img = $('.owl-item img', $slider);
                var cntImg = $img.length;

                var $owlStageOuter = $('.owl-stage-outer', $slider);
                if (!$owlStageOuter.length) {
                    return;
                }

                var carTimer = setInterval(function () {
                    var gotheight = true;
                    var cntImgLoaded = 0;

                    $img.each(function () {
                        if (this.complete) {
                            cntImgLoaded++;
                        }
                    });

                    if ($owlStageOuter.height() < 2 || cntImg !== cntImgLoaded) {
                        gotheight = false;
                    }

                    if (gotheight) {
                        clearInterval(carTimer);

                        $img.each(function () {
                            var height = $(this).height() + 20;
                            if (height > maxH) {
                                maxH = height;
                            }

                            $('.owl-item > .item', $slider).css('height', maxH);
                        });
                    }
                }, 300);
            }
        },
        collapse: function () {
            $(document).on('click', '[data-udu-toggle="collapse"]', function () {
                var target = $(this).siblings('.is-collapse');
                if ($(this).attr('aria-expanded') === 'false') {
                    $(target).slideDown().addClass('in');
                    $(this).removeClass('is-collapsed').attr('aria-expanded', 'true');
                } else {
                    $(target).slideUp().removeClass('in');
                    $(this).addClass('is-collapsed').attr('aria-expanded', 'false');
                }
            });
        },
        viewMore: function () {
            if ($('.bg-index').length && app.viewMore) {
                var featuredCss = '.cat-featured-prods';
                var newCss = '.cat-new-prods';

                $(featuredCss).length && app.viewMore.init({
                    list: featuredCss + ' .product-list2',
                    btn: featuredCss + ' .loadmore-btn'
                });

                $(newCss).length && app.viewMore.init({
                    list: newCss + ' .product-list2',
                    btn: newCss + ' .loadmore-btn'
                });
            }
        },
        footerCorrections: function () {
            var $footerGrid = $('.footer-links .links-grid');
            if ($footerGrid.height() < 10) {
                $footerGrid.css('padding', '0');
            }
        },
        userPage: function () {
            if ($('.user-register, .customer-info form, .contact-us-form').length) {
                app.forms && app.forms.initInputs();
                $('select option[value=""]').html('');
            }
        }
    }
};